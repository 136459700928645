<template>
  <div class="home">
    <iframe :style="{width:windowWidth,height:windowHeight}" :src="data" />
  </div>
</template>
<script>
    import { ref, onMounted, onUnmounted } from 'vue'

    export default {
        name: "previewFile",
        components: {},
        setup() {
            const data = ref(null);
            const windowWidth = ref('800px');
            const windowHeight = ref('500px');

            onMounted(()=>{
                windowWidth.value = window.innerWidth+'px';
                windowHeight.value = window.innerHeight+'px';
                let previewFile = localStorage.getItem('previewFile');
                data.value = ('https://view.officeapps.live.com/op/view.aspx?src=' + decodeURIComponent(previewFile));
            })

            onUnmounted(()=>{
                localStorage.removeItem('previewFile');
            })

            return { data, windowWidth, windowHeight };
        },

    };
</script>